if ($('body.designTool').length > 0) {
	
	var createChart = function (chart, data, isSym, caseTemp) {
        var tempUnit = $('#case-temp-line').find('#us').val() === 'celsius' ? 'C' : 'F';
        var container = chart === junctionChart ? 'junction-graph-holder' : 'case-graph-holder';
        var title = chart === junctionChart ? 'MTF vs Junction temperature' : 'MTF vs Case temperature';
        var xTitle = chart === junctionChart ? 'Junction temperature in ' : 'Case temperature in ';
        var tickInterval = tempUnit === 'C' ? 20 : 50;
        var min;
        var max;
        var seriesData;
        if (chart === junctionChart) {
        	min = tempUnit === 'C' ? 20 : 50;
        	max = tempUnit === 'C' ? 200 : 400;
            if (isSym) {
            	seriesData = [{
                	name: 'MTF',
    	        	color: '#00a8e1',
                	data: data.junChart
                }];
            } else {
            	seriesData = [{
    	        	name: 'MTF A',
    	        	color: '#00a8e1',
    	        	data: data.junChartA
    	        },
    	        {
    	        	name: 'MTF B',
    	        	color: '#003b71',
    	        	data: data.junChartB
    	        }];
            }
        } else {
        	min = tempUnit === 'C' ? 10 : 50;
        	max = tempUnit === 'C' ? 150 : 300;
            if (isSym) {
            	seriesData = [{
                	name: 'MTF',
    	        	color: '#00a8e1',
                	data: data.caseChart
                }];
            } else {
            	seriesData = [{
    	        	name: 'MTF A',
    	        	color: '#00a8e1',
    	        	data: data.caseChartA
    	        },
    	        {
    	        	name: 'MTF B',
    	        	color: '#003b71',
    	        	data: data.caseChartB
    	        }];
            }
        }
        
        if (typeof chart !== "undefined") {
        	chart.destroy();
        }

        Highcharts.setOptions({
            lang: {
                decimalPoint: '.',
                thousandsSep: ',',
                months: ['January', 'February', 'March', 'April', 'May', "June", 'July', 'August', 'September', 'October', 'November', 'December'],
                shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            }
        });

        chart = new Highcharts.Chart(container, {
            title: {
                text: title,
                x: -20 //center
            },
            xAxis: {
                title: {
                    text: xTitle + tempUnit+'°'
                },
                tickmarkPlacement: 'on',
                labels: {
                    align: 'center'
                },
                startOnTick: false,
                endOnTick: false,
                categories: data.junChartCats,
                min: min,
                max: max,
                tickInterval: tickInterval
            },
            yAxis: {
                title: {
                    text: 'MTF in years'
                },
                labels: {
                	formatter: function () {
                		var len = this.value.toString().length - 1;
                		return "10<sup>" + len.toString() + "</sup>";
                	},
                	useHTML: true
                },
                type: 'logarithmic'
            },
            tooltip: {
            	borderWidth: 0,
                shared: false,
                formatter: function () {
                	var result = '<b style="color: '+this.series.color+';">' + this.series.name + '</b></br>';
                	result += this.point.x + ' '+tempUnit+'°</br>';
                	result += this.point.y + ' years';
                	return result;
                },
            	useHTML: true
            },
            credits: {
                enabled: false
            },
            chart: {
                type: 'line',
                style: {
                	fontFamily: 'Open Sans,Arial,sans-serif'
                }
            },
            legend: {
            	labelFormatter: function () {
            		var result = '<span style="color: '+this.color+'">'+this.name+'</span>';
            		return result;
            	}
            },
            plotOptions: {
            	series: {
                    marker: {
                    	enabled: true
                    }
            	}
            },
            navigation: {
            	menuItemHoverStyle: {
            		background: '#003b71'
            	}
            },
            series: seriesData
        });
        return chart;
	};
	
	var addCustomPoint = function (chart, seriesIndex, y, x) {
		chart.series[seriesIndex].addPoint({
        	marker: {
        		fillColor: '#FF0000',
        		lineWidth: 4,
        		lineColor: '#FF0000'
			},
			y: y,
			x: x
		}, true, false);
	};
	
    var handleCharts = function (data, isSym) {
        var caseTemp = $('#case-temp-line').find('input#case-temp').val();
        
    	if (isSym) {
            junctionChart = createChart(junctionChart, data, isSym, caseTemp);
            caseChart = createChart(caseChart, data, isSym, caseTemp);
            
    		addCustomPoint(junctionChart, 0, data.mtf, data.junctionTemp);
    		addCustomPoint(caseChart, 0, data.mtf, caseTemp);
    	} else {
            junctionChart = createChart(junctionChart, data, isSym, caseTemp);
            caseChart = createChart(caseChart, data, isSym, caseTemp);
            
    		addCustomPoint(junctionChart, 0, data.mtfA, data.junctionTempA);
    		addCustomPoint(junctionChart, 1, data.mtfB, data.junctionTempB);
    		addCustomPoint(caseChart, 0, data.mtfA, caseTemp);
    		addCustomPoint(caseChart, 1, data.mtfB, caseTemp);
    	}
    };
}