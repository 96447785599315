if(typeof angular !== 'undefined') {
	
	var sortApp = angular.module('sortApp', []);
	
	sortApp.controller('mainController', ['$scope','$http','$sce', function($scope, $http, $sce) {
		$scope.filtered 		= 0;
		$scope.loaded 			= false;
		$scope.filters 			= {};
		$scope.numberColumns	= 0;
		$scope.loaded 			= true;
		$scope.numberResults	= 0;
		
		$scope.$watch('filtered', function(newValue, oldValue) {
			if($scope.filtered > 0) {
				$scope.enableReset();
			}
			else {
				$scope.disableReset();
			}
		});
		
		$scope.checkTextChanged = function() {
			if($('#search-text').val() !== '') {
				$scope.textChanged = 1;
			}
			else {
				$scope.textChanged = 0;	
			}
			$scope.psChanged();
		};
	
		$scope.checkTypeChanged = function() {
			if($('#search-type').val() === 'col0') {
				$scope.typeChanged = 0;
			}
			else {
				$scope.typeChanged = 1;
				$scope.resetSearchFilter();
			}
			$scope.psChanged();
		};
	
	    $scope.psChanged = function(){
	    	$scope.filtered = $scope.textChanged + $scope.typeChanged + $scope.sortChanged + $scope.colsChanged;
	    };
	    
	    var dataPath;
	    if (window.location.origin) {
	    	dataPath = window.location.origin+''+ctxPath+'/.rest/parametricsearch/v1/search';
	    } else {
	    	dataPath = window.location.protocol+''+ctxPath+'/.rest/parametricsearch/v1/search';
	    }
	    
	    $http.get(dataPath, {params:{"cat":pcat}}).success(function(data) {
	    	$scope.columns 			= data[0];
			$scope.products 		= data[1];
			$scope.filterColumns 	= data[2];
			$scope.loaded 			= true;
			$scope.numberColumns	= Object.keys($scope.columns).length;
		});
	    	    
	    $scope.$watch('sResults', function(oldv, newv) {
	    	if(oldv !== newv) {
	    		$scope.calculateResults();
	    	}
		});
		
	    $scope.calculateResults = function() {
	    	var data = $scope.sResults;
	    	var uniqueResults = {};
	    	var numberResults = 0;
			
	    	data.forEach(function(row) {
	    	    if(!uniqueResults[row.col0.value]) {
	    	    	uniqueResults[row.col0.value] = {};
	    	    	numberResults++;
	    	    }
	    	});
	    	$scope.numberResults = numberResults;
	    };
	    
		$scope.reset = function(){
			$scope.sortType     = 'col0.value';
			$scope.sortReverse  = false;
			$scope.textChanged 	= 0;
		    $scope.typeChanged 	= 0;
		    $scope.sortChanged 	= 0;
		    $scope.colsChanged 	= 0;
			$scope.filtered		= 0;
			$scope.query 		= {};
		    $scope.queryBy 		= 'col0';
		    
		    // reset all filters
		    $('th i').removeClass('inactive');
			$('.parametric-search #ps').find('th, td').show();
			$('.parameters ul li a').addClass('active');
		};
		
		$scope.resetSearchFilter = function(){
	    	$scope.query = {};
	    	$('#search-text').focus();
	    	$scope.filtered = 0;
	    };
		
		$scope.setSort = function(type,reverse,$event) {
			$scope.sortType  	= type+'.value';
			$scope.sortReverse  = reverse;
			$scope.sortChanged 	= 1;
			$scope.psChanged();
			
			// reset all filters
			$('th i').removeClass('inactive');
			
			var icons = angular.element($event.currentTarget);
			if(reverse === true) {
				$(icons).children('.arrow-down').addClass('inactive');
			}
			else {
				$(icons).children('.arrow-up').addClass('inactive');
			}		
		};
	
		$scope.enableReset = function() {
			$('.parametric-search #reset-filters').attr('disabled',false);
		};
		
		$scope.disableReset = function() {
			$('.parametric-search #reset-filters').attr('disabled',true);	
		};
	
		$scope.toggleOverlay = function() {
			$('.parametric-search .filter-column').removeClass('active');
			
			if($('.parametric-search .parameters').hasClass('active')) {
				$('.parametric-search .parameters').removeClass('active');
			}
			$('.parametric-search .overlay').toggleClass('active');
		};
		
		$scope.toggleParametersBox = function() {
			$scope.toggleOverlay();
			$('.parametric-search .parameters').toggleClass('active');
		};
	
		$scope.setActive = function(column) {
			$('.parametric-search #ps').find('th[data-column='+column+'], td[data-column='+column+']').addClass('col-active');
		};
		
		$scope.setDisabled = function(column) {
			$('.parametric-search #ps').find('th[data-column='+column+'], td[data-column='+column+']').removeClass('col-active');
		};
		
		$scope.toggleColumn = function(column, $event){
			var el = angular.element($event.currentTarget);
			$(el).toggleClass('active');
			$('.parametric-search #ps').find('th[data-column='+column+'], td[data-column='+column+']').toggleClass('disabled');
			
			if($('.parameters.active ul li a.active').length === $scope.numberColumns) {
				$scope.colsChanged = 0;	
			}
			else {
				$scope.colsChanged = 1;
			}
			$scope.psChanged();
		};
	 
		$scope.reset();
	}]);
	
	// filters
	sortApp.filter('to_trusted', ['$sce', function($sce){
	    return function(text) {
	        return $sce.trustAsHtml(text);
	    };
	}]);
	
		sortApp.filter('orderByKey',function(){
		return function(input) {
			var results = [];
			for(var key in input) {
				results.push(key);
			}
			results.sort();
			
			var structuredCols = {};
			angular.forEach(results, function(value, key){
				structuredCols[value] = input[value];
			});
			return structuredCols;
		};
	});
	
	// directives
	sortApp.directive('column', function() {
	  return {
	      restrict: 'E',
	      scope: {
	        coldata: '='
	      },
	      
	      link: function (scope, element, attrs) {
			if(scope.coldata.url) {
				element.html('<a href="'+scope.coldata.url+'">'+scope.coldata.value+'</a>');
			}
			else {
				element.html(scope.coldata.value);
			}
		}
	  };
	});
}