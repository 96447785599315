$(function(){
    $(document).ready(function () {
        //svg
        var $worldMap = $('#worldMap');
        var $clickableMapRegion = $('#clickableMapRegion');
        var $clickableMapCountry = $('#clickableMapCountry');
        var $clickableMapCategory = $('#clickableMapCategory');
        var $countriesOptions = $('option[data-region]');
        var $clickableCategoriesDivs = $('div[data-category]');
        var $partnerDivs = $('div[data-region-only]');

        //var $allTextImages = $('div[data-region][data-country]');
        var $salesRepresentativesHeader = $('[data-category=\'sales\']');
        var $salesRepresentatives = $('[data-category=\'sales\'] div[data-region][data-country]');
        var $locationsHeader = $('[data-category=\'locations\']');
        var $locations = $('[data-category=\'locations\'] div[data-region][data-country]');
        var $partnersHeader = $('[data-category=\'partners\']');
        var $partners = $('[data-category=\'partners\'] div[data-region][data-country]');

        function hideAllItems() {
            $salesRepresentatives.hide();
            $locations.hide();
            $partners.hide();
        }

        function showAllItems() {
            $salesRepresentatives.show();
            $locations.show();
            $partners.show();
        }

        function filterAllItems(filter) {
            $salesRepresentatives.filter(filter).show();
            $locations.filter(filter).show();
            $partners.filter(filter).show();
        }

        /**
         * Function to hide headers of sections that don't have any visible childs
         */
        function filterEmptySections() {
            $salesRepresentativesHeader.show();
            $locationsHeader.show();
            $partnersHeader.show();
            if ($salesRepresentatives.filter(':visible').length < 1) {
                $salesRepresentativesHeader.hide();
            }
            if ($locations.filter(':visible').length  < 1) {
                $locationsHeader.hide();
            }
            if ($partnerDivs.filter(':visible').length  < 1) {
                $partnersHeader.hide();
            }
        }

        function regionChanged(val) {

            hideAllItems();
            filterAllItems('div[data-region="' + val + '"]');

            $partnerDivs.hide();
            $countriesOptions.hide();
            $countriesOptions.filter('option[data-region="' + val + '"]').show();

            $clickableMapCountry.val('all');
            $partnerDivs.filter('div[data-region-only="' + val + '"]').show();
            filterEmptySections();
        }

        $worldMap.ready(function() {

            var $worldMapSvg = $worldMap.getSVG();
            // first check if worldMapSvg has not returned undefined
            if ($worldMapSvg) {
                var $china = $worldMapSvg.find('#china');
                var $japan = $worldMapSvg.find('#japan');
                var $americas = $worldMapSvg.find('#americas');
                var $emea = $worldMapSvg.find('#emea');
                var $sap = $worldMapSvg.find('#sap');
                var $korea = $worldMapSvg.find('#korea');

                //for some reason it doesn't work on entire collection...

                $china.on('click', function (e) {
                    var val = 'china';
                    $clickableMapRegion.val(val);
                    regionChanged(val);
                });

                $japan.on('click', function (e) {
                    var val = 'japan';
                    $clickableMapRegion.val(val);
                    regionChanged(val);
                });

                $americas.on('click', function (e) {
                    var val = 'americas';
                    $clickableMapRegion.val(val);
                    regionChanged(val);
                });

                $emea.on('click', function (e) {
                    var val = 'emea';
                    $clickableMapRegion.val(val);
                    regionChanged(val);
                });

                $sap.on('click', function (e) {
                    var val = 'sap';
                    $clickableMapRegion.val(val);
                    regionChanged(val);
                });

                $korea.on('click', function (e) {
                    var val = 'korea';
                    $clickableMapRegion.val(val);
                    regionChanged(val);
                });
            }
        });

        $clickableMapRegion.on('change', function (e) {
            if ('all' !== $clickableMapRegion.val()) {
                showAllItems();
                regionChanged($clickableMapRegion.val());
            } else {
                $clickableMapCountry.val('all');
                $clickableMapCategory.val('all');
                $countriesOptions.show();
                $clickableCategoriesDivs.show();
                $partnerDivs.show();
                showAllItems();
            }
        });

        $clickableMapCountry.on('change', function (e) {
            var originalValue = $(this).val();
            hideAllItems();
            var optionSelected = $("option:selected", this);
            if ('all' !== originalValue) {
                $partnerDivs.hide();
                $countriesOptions.hide();
                $countriesOptions.filter('option[data-region="' + optionSelected.data('region') + '"]').show();
                $partnerDivs.filter('div[data-region-only="' + optionSelected.data('region') + '"]').show();
                $clickableMapRegion.val(optionSelected.data('region'));


                var countryFilter = 'div[data-region="' + optionSelected.data('region') + '"]div[data-country="' + optionSelected.val() + '"]';
                $salesRepresentatives.filter(countryFilter).show();
                $locations.filter(countryFilter).show();
                $partners.filter('div[data-region="' + optionSelected.data('region') + '"]').show();
                filterEmptySections();
            } else {
                filterAllItems('div[data-region="' + $clickableMapRegion.val() + '"]');
            }
        });


        $clickableMapCategory.on('change', function (e) {
            $clickableCategoriesDivs.hide();
            $clickableMapCountry.show();
            if ('all' !== $clickableMapCategory.val()) {
                $clickableCategoriesDivs.filter('div[data-category="' + $clickableMapCategory.val() + '"]').show();
                if('partners' === $clickableMapCategory.val()){
                    $clickableMapCountry.hide();
                }
            } else {
                $clickableCategoriesDivs.show();
            }
        });
    });
});
