function validateSubscriptionEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function redirectUrl(encryptedEmail) {
    var assignedLink = $('.newsletter-subscription-wrapper form').attr('action');
    window.location.href = assignedLink + "?email=" + encryptedEmail;
}

$('#subscription-email').on('keyup', function() {
    $('.invalid-email-tooltip').hide();
    $('#subscription-email').css('color', '#333');
});

$('#subscribe-button').on('click', function(e) {
    e.preventDefault();

    var enteredEmail = $('#subscription-email').val().trim();

    // Validate email
    if (validateSubscriptionEmail(enteredEmail)) {

        // Encrypt email and pass as parameter
        var encryptedEmailAddress = btoa(enteredEmail);

        // Change window href location
        redirectUrl(encryptedEmailAddress);
    } else {
        $('.invalid-email-tooltip').fadeIn();
    }
});