$(function(){
    $(document).ready(function () {
        var $trAll = $('tr[name]');
        var $filter = $('#filter');

        function filter(val) {
            $trAll.show();
            $trAll.filter(':not(tr[name*="' + val + '"])').hide();
        }

        function reset() {
            $filter.val('');
            $trAll.show();
        }

        $('#search').on('click', function (e) {
            e.preventDefault();
            var val = $filter.val().toLowerCase();
            if ('' !== val || val === undefined) {
                filter(val);
            } else {
                reset();
            }
        });

        $('#reset').on('click', function (e) {
            e.preventDefault();
            reset();
        });

        $filter.on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
            }
        });

        $filter.on('keyup', function (e) {
            if (e.which === 13) {
                var val = $filter.val().toLowerCase();
                if ('' !== val || val === undefined) {
                    filter(val);
                } else {
                    reset();
                }
                return false;
            }
        });
    });
});