$(function(){
    $(document).ready(function () {
        var $labels = $("label:contains('*')").filter(function() {
            return this.innerText !== undefined && this.innerText.endsWith('*');
        });

        var id;

        for(var i = 0; i < $labels.length; i++) {
            id = $labels.eq(i).attr('for');
            $('#' + id).attr('required', 'required');
        }
    });
});