$(function(){
    /*
     * Breadcrumb
     * Description: Breadcrumb interaction for smaller screens or when the breadcrumb is just too long
     *
     * Created by JvanderHeide on 2014-10-17.
     * Version 1.1
     */

    var elements,
        bcNav = $('.breadcrumb-navigation'),
        bc =  bcNav.find('.breadcrumb'),
        hoverInterval = false,
        left = true,
        touch = false,
        hoverAreaRatio = 0.25,
        scrollBc,
        hoverAreaSize;

    //Always push breadcrumb as far right as possible
    if (bcNav[0]) {
        setTimeout( function() {
            bc[0].scrollLeft = bcNav.outerWidth() + bcNav[0].scrollWidth;
            bc.addClass('show');
        }, 100 );
    }

    bcNav.on('mouseenter mousemove', function(e){
        if(touch) {
            return;
        }
        var x = e.pageX - bcNav.offset().left;
        var y = e.pageY - bcNav.offset().top;
        var _left = (x < hoverAreaSize(true));
        var right = (x > hoverAreaSize(false));

        left = _left;
        scrollBc();

        if ( !left && !right) {
            clearInterval(hoverInterval);
            hoverInterval = false;
        }

    });

    bcNav.on('mouseleave', function(e){
        if(touch) {
            return;
        }
        clearInterval(hoverInterval);
        hoverInterval = false;
    });

    $(document).on('touchstart', function(){
        touch = true;
    });

    scrollBc = function() {
        if(!hoverInterval && !touch) {
            hoverInterval = setInterval(function(){
                if(left) {
                    bc[0].scrollLeft -= 1;
                } else {
                    bc[0].scrollLeft += 1;
                }
            }, 10);
        }
    };

    hoverAreaSize = function(left) {
        var bcNavWidth = bcNav.outerWidth();
        var pcToPx = hoverAreaRatio * bcNavWidth;
        if(!left) {
            pcToPx = bcNavWidth - pcToPx;
        }
        return pcToPx;
    };


});