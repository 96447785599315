$(function(){
	$('table.tablesorter').tablesorter();
    FastClick.attach( document.body );
    
    $('[data-toggle="tooltip"]').tooltip({container: 'body', html: "true"});

    $(window).on('load', function() {
    	$('.company_video').html('<iframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/pVda-UCL158?modestbranding=1&amp;rel=0&amp;showinfo=0&amp;vq=hd720" frameborder="0" allowfullscreen></iframe>');
    	$('.company_video2').html('<iframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/WHgwNAJIENc?modestbranding=1&amp;rel=0&amp;showinfo=0&amp;vq=hd720" frameborder="0" allowfullscreen></iframe>');
    });
        
     // toggle tab when clicking an external tab link
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var target = this.href.split('#');
        $('.nav a').filter('a[href="#'+target[1]+'"]').tab('show');
    });
    
    $('table.ampleon').each(function(){
    	$(this).wrap('<div class="table-responsive"></div>');
    });

    if ($('.search-box')) {
        if ($('.search-box').next().css('background-color') === 'rgb(248, 248, 248)') {
            $('.main-content-wrapper').css('background-color', '#f8f8f8');
        } else {
            $('.main-content-wrapper').css('background-color', '#ffffff');
        }
    }

    // Applications & Products section

    $('.applications-products-description i').on('click', function() {
        $(this).parent().prev().toggleClass('expanded-description-details');
        $(this).toggleClass('expanded-section-icon');
    });

    $('.sorted-items-expander').on('click', function() {
        $('.sorted-items-expander i').removeClass('expanded-description-cross');

        if ($(this).hasClass('collapsed')) {
            $(this).find('i').addClass('expanded-description-cross');
        } else {
            $(this).find('i').removeClass('expanded-description-cross');
        }
    });
});