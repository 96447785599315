
$('#acceptButton').on('click', function(){
	if ($('#acceptButton').attr("cookieId") === "media-download" || $('#acceptButton').attr("cookieId") === "design-tool") {
		//$.cookie("disclaimer-media-download", "accepted", { expires : 10 });
		$('#disclaimerModal').modal('toggle');
	}
}); 

$(document).ready(function() {
    if ($('#disclaimerModal').length){
		//$('#disclaimerModal').modal('toggle');
    	/*if($.cookie("disclaimer-media-download") !== "accepted") {
    		$('#disclaimerModal').modal('toggle');
    	}*/
    }
});
 