$(function(){
    $(document).ready(function () {
        // check if element is there
        var $downloadDocumentLink = $('#downloadDocumentLink');
        var $contactSubmitForm = $('.infusion-form');
        var documentUrl = $.cookie('documentUrl');
        var subscribed = $.cookie('subscribed');

        if($downloadDocumentLink.length > 0) {
            $downloadDocumentLink.attr('href', documentUrl);
        }

        if ((subscribed === undefined || 'no' === subscribed) && $contactSubmitForm.length > 0 && documentUrl !== undefined) {
            $contactSubmitForm.on('submit', function (e) {
                var form = this;
                $.removeCookie('documentUrl', { path: '/' });
                $.cookie('subscribed', 'yes', { path: '/' });
                $downloadDocumentLink.get(0).click();
                e.preventDefault();
                setTimeout( function () {
                    form.submit();
                }, 1000);
            });
        }
    });
});