$(function () {
    const toggleDialog = function () {
        $(".popup").toggleClass("hidden");
        $(".info-bubble").toggleClass("hidden");
        $(document.body).css("overflow", "hidden");
    };

    const redirectToDownloadPage = function () {
        var uuid = $("#uuid").text();
        window.location.href = '/home/downloads/' + uuid + '.html';
    };

    const setCookie = function () {
        var realUuid = $("#realUuid").text().replace(/[\r\n]/gm, '');
        $.cookie("tos_accepted_"+realUuid, "true", { path: '/' });
    };

    $('#tosBtn-accept').click(function () {
        setCookie();
        redirectToDownloadPage();
    });

    $('#tosBtn-decline').click(function () {
        toggleDialog();
    });

    $('#tos-confirmation-yes').click(function () {
        history.back();
    });

    $('#tos-confirmation-no').click(function () {
        toggleDialog();
    });
});
