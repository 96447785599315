if ($('.media-download').length > 0) {
	var getData = function(attrs, parent) {
		var dataPath;
	    if (window.location.origin) {
	    	dataPath = window.location.origin+''+ctxPath+'/.rest/mediadownload/v1/search';
	    } else {
	    	dataPath = window.location.protocol+''+ctxPath+'/.rest/mediadownload/v1/search';
	    }
	    
	    $.getJSON(dataPath, attrs).done(function(data) {
	    	$('.filter-results').remove();
	    	$('.pagination').closest('nav').remove();
	    	parent.append(data.paginationTop);
	    	parent.append(data.assets);
	    	parent.append(data.paginationBottom);
		});
	};
	
	var paginationClick = function() {
		$('.tab-pane.active').on('click', '.pagination a', function (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
			if (!$(this).parent().hasClass('disabled')) {
				var current = $(this).data('page');
				var parent = $('.tab-pane.active');
				var q = parent.find('input.downloads-query').val();
				var attrs = [{name: 'q', value: q},
			                 {name: 'tab', value: parent.attr('id')},
			                 {name: 'page', value: current}];
				getData(attrs, parent);
				$('html, body').animate( {
					scrollTop: $('.tab-content').offset().top - 70
				}, 100);
				parent.find('input[name="page"]').val(current);
			}
		});
	};
	
	var downloadData = function(attrs) {
		var url;
	    if (window.location.origin) {
	    	url = window.location.origin+''+ctxPath+'/.rest/mediadownload/v1/allmedia';
	    } else {
	    	url = window.location.protocol+''+ctxPath+'/.rest/mediadownload/v1/allmedia';
	    }
		
	    var form = $('<form></form>').attr('action', url).attr('method', 'get');
	    for (var i = 0; i < attrs.length; i++) {
	    	form.append($("<input></input>").attr('type', 'hidden').attr('name', attrs[i].name).attr('value', attrs[i].value));
	    }
		form.appendTo('body').submit().remove();
	};
	
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var target = this.href.split('#');
        var dataPath;
        if (window.location.origin) {
        	dataPath = window.location.origin+''+ctxPath+'/.rest/mediadownload/v1/search';
        } else {
        	dataPath = window.location.protocol+''+ctxPath+'/.rest/mediadownload/v1/search';
        }

		var q = $('.tab-pane.active input.downloads-query').val();
        var attrs = [{name: 'q', value: q},
                     {name: 'tab', value: target[1]},
                     {name: 'page', value: '1'}];
        var parent = $('.tab-content').find('#'+target[1]);
        $.getJSON(dataPath, attrs).done(function(data) {
        	$('.filter-results').remove();
        	$('.pagination').closest('nav').remove();
        	parent.append(data.paginationTop);
        	parent.append(data.assets);
        	parent.append(data.paginationBottom);
    	});
        
        paginationClick();
    });
	
	$('.media-search').on('click', function(e) {
		var q = $('.tab-pane.active input.downloads-query').val();
		var parent = $(this).closest('.tab-pane.active');
	    
	    var attrs = $(this).closest('.form-query').serializeArray();
	    attrs = $.grep(attrs, function(n) {
	    	return n.name !== "page";
		});
	    attrs.push({name: 'page', value: '1'});
	    getData(attrs, parent);
	
		parent.find('input[name="prev-query"]').val(q);
	});
	
	$('.download-zip').on('click', function(e) {
	    var attrs = $(this).closest('.form-query').serializeArray();
	    downloadData(attrs);
	});
	
	$('input.downloads-query').on('keyup', function(e) {
		if (e.which === 13) {
			$(this).closest('.form-query').find('.media-search').click();
		}
	});
	
	$('.media-reset').on('click', function(e) {
		var parent = $(this).closest('.tab-pane.active');
		parent.find('input.downloads-query').val('');
		var attrs = [{name: 'q', value: ''},
	                 {name: 'tab', value: parent.attr('id')},
	                 {name: 'page', value: '1'}];
	    getData(attrs, parent);
		parent.find('input[name="prev-query"]').val('');
		parent.find('input[name="page"]').val('1');
	});
	
	$('.form-query').on('submit', function(e) {
		e.preventDefault();
	});
	
	$('body.mediaDownload').ready( function() {
		var parent = $('.tab-pane.active');
		var attrs = [{name: 'q', value: ''},
	                 {name: 'tab', value: parent.attr('id')},
	                 {name: 'page', value: '1'}];
		getData(attrs, parent);
		
		paginationClick();
	});
}