$(function(){

    function calculateWidth(elements) {
        return parseFloat(100 / elements);
    }

    function generateTabs(columnData, columnWidth, first){
        var listElement = $('<ul class="document-portfolio-category-tabs tabs nav nav-tabs" role="tablist"></ul>');
            columnData.each(function(){
            var listitem = $('<li role="presentation" class="tab">'+$(this).html()+'</li>');
            listitem.css('width',columnWidth+'%');

            if(first === true) {
                listitem.addClass('active');
                first = false;
            }

            $(listitem).click(function () {
                $('.document-portfolio-category li').removeClass('active');
            });
            listElement.append(listitem);
        });
        return listElement;
    }

    $('.document-portfolio-category').each(function(){
        var $this = $(this);

        var tabs = $(this).find('.tab');
        var tabsLength = tabs.length;

        // if more than 5 subcategories, divide over 2 levels, calculate how many per level
        if(tabsLength > 5) {
            var itemsLevel1 = 0;
            var itemsLevel2 = 0;

            var secondLevelLength = parseInt(tabsLength - 5);
            switch(secondLevelLength) {
                case 1:
                    itemsLevel1 = 4;
                    itemsLevel2 = 2;
                    break;
                case 2:
                    itemsLevel1 = 5;
                    itemsLevel2 = 2;
                    break;
                case 3:
                    itemsLevel1 = 5;
                    itemsLevel2 = 3;
                    break;
                case 4:
                    itemsLevel1 = 5;
                    itemsLevel2 = 4;
                    break;
                case 5:
                	itemsLevel1 = 5;
                	itemsLevel2 = 5;
            }

            // calculate width per item in a level
            var itemLevel1Width = calculateWidth(itemsLevel1);
            var itemLevel2Width = calculateWidth(itemsLevel2);

            // generate tables
            var level1 = generateTabs($this.find('.tab').slice(0,itemsLevel1),itemLevel1Width,true);
            var level2 = generateTabs($this.find('.tab').slice(itemsLevel1),itemLevel2Width,false);
            $this.append(level1);
            $this.append(level2);
        }
        else {
            var itemWidth = calculateWidth(tabsLength);
            var tabsList = generateTabs($this.find('.tab'),itemWidth,true);
            $this.append(tabsList);
        }

        $this.find('.tabs-original').remove();
    });
});