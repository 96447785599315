$(function(){

    function scrollToAnchor(anchor) {
        var anchorTag = $('a[name="' + anchor + '"');
        $('html,body').animate({scrollTop: anchorTag.offset().top - 70}, 400);
    }

    $('.eolClick').on('click', function(e){
        setTimeout(function() {
            scrollToAnchor('discontinuation');
        },100);
    });

    $('#submitDesignToolForm').click(function (e) {
        e.preventDefault();
        var product = $('input[name="product"]').val();
        var caseTemp = $('input[name="temp"]').val();

        var dataPath;
        if (window.location.origin) {
            dataPath = window.location.origin + '' + ctxPath + '/design-tool/' + product + ".html?caseTemp=" + caseTemp;
        } else {
            dataPath = window.location.protocol + '' + ctxPath + '/design-tool/' + product + ".html?caseTemp=" + caseTemp;
        }
        window.location.assign(dataPath);
    });

    $('.btn-link').click(function (e) {
        e.preventDefault();
        var product = $(this).parent().find('input[name="product"]').val();
        var caseTemp = 80;

        var dataPath;
        if (window.location.origin) {
            dataPath = window.location.origin + '' + ctxPath + '/design-tool/' + product + ".html?caseTemp=" + caseTemp;
        } else {
            dataPath = window.location.protocol + '' + ctxPath + '/design-tool/' + product + ".html?caseTemp=" + caseTemp;
        }
        window.location.assign(dataPath);
    });

    $(document).ready(function () {
        $('li[role="presentation"] a[href="' + window.location.hash + '"]').click();
    });
});
