if ($('body.designTool').length > 0) {

    var hardLimitReached = false; // used for input limit checking
    var defaultPin;
    var defaultPinA;
    var defaultPinB;
    var drainCurr;
    var drainCurrA;
    var drainCurrB;

    var junctionChart;
    var caseChart;

    var getUrlParam = function (paramName) {
        var decodedUrl = decodeURIComponent(window.location.search.substring(1));
        var urlParams = decodedUrl.split('&');
        var paramKey;

        for (var i = 0; i < urlParams.length; i++) {
            paramKey = urlParams[i].split('=');

            if (paramKey[0] === paramName) {
                return paramKey[1] === undefined ? true : paramKey[1];
            }
        }
        return "";
    };

    var checkLimits = function (field, fieldName, unit, hardMin, hardMax, softMin, softMax) {
        var fieldVal = field.val();

        if (field.hasClass('temp')) {
            if ($('#us').val() === 'fahrenheit') {
            	hardMin = hardMin != null ? hardMin * 1.8 + 32 : null;
                hardMax = hardMax != null ? hardMax * 1.8 + 32 : null;
                softMin = softMin != null ? softMin * 1.8 + 32 : null;
                softMax = softMax != null ? softMax * 1.8 + 32 : null;
                unit = "°F";
            }
        }

        field.removeClass('soft-limit-broken');
        field.removeClass('hard-limit-broken');

        var formatValue = function (value) {
            if (value < 1) {
                return value.toFixed(4);
            }
            return Math.round(value * 100) / 100;
        };

        // did it break soft limits?
        if (softMin != null && fieldVal < softMin) {
            field.addClass('soft-limit-broken');
            if ($('#soft-limit-error').is(':hidden')) {
	            $('#soft-field').text(fieldName);
	            $('#soft-more-less').text("more");
                $('#soft-value').text(formatValue(softMin));
	            $('#soft-error-unit').text(unit);
                $('#soft-limit-error').show();
                $('#status-ok').hide();
            }
        } else if (softMax != null && fieldVal > softMax) {
            field.addClass('soft-limit-broken');
            if ($('#soft-limit-error').is(':hidden')) {
	            $('#soft-field').text(fieldName);
	            $('#soft-more-less').text("less");
                $('#soft-value').text(formatValue(softMax));
	            $('#soft-error-unit').text(unit);
                $('#soft-limit-error').show();
                $('#status-ok').hide();
            }
        }

        // did it break hard limits?
        if (hardMin != null && fieldVal < hardMin) {
            hardLimitReached = true;
            field.addClass('hard-limit-broken');
            if ($('#hard-limit-error').is(':hidden')) {
	            $('#hard-field').text(fieldName);
	            $('#hard-more-less').text("more");
                $('#hard-value').text(formatValue(hardMin));
	            $('#hard-error-unit').text(unit);
                $('#hard-limit-error').show();
                $('#status-ok').hide();
            }
        } else if (hardMax != null && fieldVal > hardMax) {
            hardLimitReached = true;
            field.addClass('hard-limit-broken');
            if ($('#hard-limit-error').is(':hidden')) {
	            $('#hard-field').text(fieldName);
	            $('#hard-more-less').text("less");
                $('#hard-value').text(formatValue(hardMax));
	            $('#hard-error-unit').text(unit);
                $('#hard-limit-error').show();
                $('#status-ok').hide();
            }
        }
    };

    var checkOutputLimits = function (symmetric) {
        if (symmetric) {
            //Output fields
            checkLimits($('#junc-temp'), "Junction Temperature", "°C", null, maxTj, null, null);

            //MTF does not have limits
            checkLimits($('#eta-D'), "ηD", "", null, null, 0.1, 0.9);
            checkLimits($('#eta-PAE'), "ηPAE", "", null, null, 0.1, 0.9);
        } else {

            //Output fields
            checkLimits($('#junc-temp-a'), "Junction Temperature section A", "°C", null, maxTj, null, null);
            checkLimits($('#junc-temp-b'), "Junction Temperature section B", "°C", null, 200, null, null);

            //MTF A & B do not have limits
            checkLimits($('#eta-D-a'), "ηD section A", "", null, null, 0.1, 0.9);
            checkLimits($('#eta-D-b'), "ηD section B", "", null, null, 0.1, 0.9);
            checkLimits($('#eta-PAE-a'), "ηPAE section A", "", null, null, 0.1, 0.9);
            checkLimits($('#eta-PAE-b'), "ηPAE section B", "", null, null, 0.1, 0.9);
        }
    };

    var getData = function () {
        var dataPath;
        if (window.location.origin) {
            dataPath = window.location.origin + '' + ctxPath + '/.rest/designtool/v1/data';
        } else {
            dataPath = window.location.protocol + '' + ctxPath + '/.rest/designtool/v1/data';
        }

        var sym = $('#symmetry-selector').val();
        var inputsArr = [];
        var inputs =
            sym === "sym" ?
                $('#sym :input:not(.default), #rth-line-sym :input, #pulsed-cond-line :input, #case-temp-line :input').serializeArray() :
                $('#asym :input:not(.default), #rth-line-asym :input, #pulsed-cond-line :input, #case-temp-line :input').serializeArray();
        $.each(inputs, function (index, element) {
            inputsArr.push(element.name + "=" + element.value);
        });
        var defaultsArr = [];
        var defaults =
            sym === "sym" ?
                $('#sym :input.default').serializeArray() :
                $('#asym :input.default').serializeArray();
        $.each(defaults, function (index, element) {
            if (element.name === "drain-curr") {
                defaultsArr.push(element.name + "=" + drainCurr);
            } else if (element.name === "drain-curr-a") {
                defaultsArr.push(element.name + "=" + drainCurrA);
            } else if (element.name === "drain-curr-b") {
                defaultsArr.push(element.name + "=" + drainCurrB);
            } else if (element.name === "input-power") {
                defaultsArr.push(element.name + "=" + defaultPin);
            } else if (element.name === "input-power-a") {
                defaultsArr.push(element.name + "=" + defaultPinA);
            } else if (element.name === "input-power-b") {
                defaultsArr.push(element.name + "=" + defaultPinB);
            }
        });

        var attrs = [{name: 'dtid', value: dtid},
            {name: 'form', value: inputsArr},
            {name: 'sym', value: sym},
            {name: 'defaults', value: defaultsArr}];

        $.getJSON(dataPath, attrs).done(function (data) {

            if (sym === "sym") {
                $('#output-sym').find('input#junc-temp').val(data.junctionTemp);
                $('#output-sym').find('input#MTF').val(data.mtf);
                $('#output-sym').find('input#eta-D').val(data.etaD);
                $('#output-sym').find('input#eta-PAE').val(data.etaPAE);

                handleCharts(data, true);

                checkOutputLimits(true);
            } else if (sym === "asym") {
                $('#output-asym').find('input#junc-temp-a').val(data.junctionTempA);
                $('#output-asym').find('input#junc-temp-b').val(data.junctionTempB);
                $('#output-asym').find('input#MTF-a').val(data.mtfA);
                $('#output-asym').find('input#MTF-b').val(data.mtfB);
                $('#output-asym').find('input#eta-D-a').val(data.etaDA);
                $('#output-asym').find('input#eta-D-b').val(data.etaDB);
                $('#output-asym').find('input#eta-PAE-a').val(data.etaPAEA);
                $('#output-asym').find('input#eta-PAE-b').val(data.etaPAEB);

                handleCharts(data, false);

                checkOutputLimits(false);
            }
        });
    };

    var updateRth = function () {
		var unit = $('#case-temp-line').find('#us').val();
	    var caseTemp = $('#case-temp').val();
	    if (unit === "fahrenheit") {
	    	caseTemp = (caseTemp - 32) / 1.8;
	    }

	    var pout = $('#output-power-line-sym').find('input').val();
	    var vds = $('#drain-volt-line-sym').find('input').val();
	    var ids;
	    if ($('#drain-curr-line-sym').find('input').hasClass('default')) {
	    	ids = drainCurr;
	    } else {
	    	ids = $('#drain-curr-line-sym').find('input').val();
	    }

		var rth = (coeffA+coeffB*caseTemp)/(1-coeffB*pout*((1/(pout/(vds*ids)))-1));

		$('#rth-line-sym').find('input#rth').val(rth);
		$('#rth-line-sym').find('input.display-value').val(rth.toFixed(2));
    };

    var initData = function () {
    	var temp = getUrlParam("caseTemp");
    	if (temp.length > 0) {
    		$('input[name="case-temp"]').val(temp);
    	}

        var inputPowerSymEl = $('#input-power-line-sym').find('input');
        var inputPowerASymAEl = $('#input-power-line-asym-a').find('input');
        var inputPowerASymBEl = $('#input-power-line-asym-b').find('input');

        var drainCurrSymEl = $('#drain-curr-line-sym').find('input');
        var drainCurrASymAEl = $('#drain-curr-line-asym-a').find('input');
        var drainCurrASymBEl = $('#drain-curr-line-asym-b').find('input');

        var outputPowerSymEl = $('#output-power-line-sym').find('input');
        var outputPowerSymAEl = $('#output-power-line-asym-a').find('input');
        var outputPowerSymBEl = $('#output-power-line-asym-b').find('input');

        var formatValue = function (value) {
            if (value < 1) {
                return value.toFixed(4);
            }
            return parseFloat(value.toFixed(2));
        };

        var poutDbm = 10 * Math.log(1000 * defaultPout) / Math.LN10;
        var poutDbmA = 10 * Math.log(1000 * defaultPoutA) / Math.LN10;
        var pinDbm = poutDbm - defaultGain;
        var pinDbmA = poutDbmA - defaultGain;
        defaultPin = Math.pow(10, pinDbm / 10) / 1000;
        defaultPinA = Math.pow(10, pinDbmA / 10) / 1000;

        inputPowerSymEl.val(formatValue(defaultPin));

        var poutDbmB = 10 * Math.log(1000 * defaultPoutB) / Math.LN10;
        var pinDbmB = poutDbmB - defaultGain;
        defaultPinB = Math.pow(10, pinDbmB / 10) / 1000;
        inputPowerASymAEl.val(formatValue(defaultPinA));
        inputPowerASymBEl.val(formatValue(defaultPinB));

        drainCurr = defaultPout / (nd * defaultVds);
    	drainCurrA = defaultPoutA / (nd * defaultVds);
        drainCurrB = defaultPoutB / (nd * defaultVds);
        drainCurrSymEl.val(Math.round(drainCurr * 100) / 100);
        drainCurrASymAEl.val(Math.round(drainCurrA * 100) / 100);
        drainCurrASymBEl.val(Math.round(drainCurrB * 100) / 100);

        outputPowerSymEl.val(defaultPout);
    	outputPowerSymAEl.val(defaultPoutA);
    	outputPowerSymBEl.val(defaultPoutB);

        if (isGaN) {
        	updateRth();
        }
    };

    // Standard calculation for min/max power in/out soft limits.
    // Power and gain speak for themselves, IO determines whether gain needs to be added or subtracted.
    // IO == in == subtraction, IO out == addition.
    var powerIOSoftLimit = function (power, gain, IO) {
        var output;
        if (IO === "in") {
            output = Math.pow(10, (10 * (Math.log(1000 * power) / Math.LN10) - gain) / 10) / 1000;
        } else if (IO === "out") {
            output = Math.pow(10, (10 * (Math.log(1000 * power) / Math.LN10) + gain) / 10) / 1000;
        }
        return output;
    };

    var checkInputLimits = function () {
        hardLimitReached = false;

        $('[id$=-limit-error]').hide();
        $('#status-ok').show();

        var symmetry = $('#symmetry-selector').val();
        var maxGain = defaultGain + 3;
        var minGain = maxGain - 10;

        // checklimit arguments: field, fieldName, unit, hardMin, hardMax, softMin, softMax
        // null arguments = N/A
        checkLimits($('#case-temp'), "Case Temperature", "°C", null, null, 10, 150);

        if (symmetry === "asym") { // check all asymmetrical fields
            var inputPowerAsymA = $('#input-power-asym-a');
            var inputPowerAsymB = $('#input-power-asym-b');
            var outputPowerAsymA = $('#output-power-asym-a');
            var outputPowerAsymB = $('#output-power-asym-b');
            var pInA = defaultPinA;
            var pInB = defaultPinB;
            var pOutA = defaultPoutA;
            var pOutB = defaultPoutB;

            //Input fields
            var pInMinSoftA = powerIOSoftLimit(pOutA, maxGain, "in");
            var pInMinSoftB = powerIOSoftLimit(pOutB, maxGain, "in");
            var pInMaxSoftA = powerIOSoftLimit(pOutA, minGain, "in");
            var pInMaxSoftB = powerIOSoftLimit(pOutB, minGain, "in");
            var pOutMinSoftA = powerIOSoftLimit(pInA, minGain, "out");
            var pOutMinSoftB = powerIOSoftLimit(pInB, minGain, "out");
            var pOutMaxSoftA = powerIOSoftLimit(pInA, maxGain, "out");
            var pOutMaxSoftB = powerIOSoftLimit(pInB, maxGain, "out");
            var pOutMaxCombCapA = 1.5 * pOut1dBA;
            var pOutMaxCombCapB = 1.5 * pOut1dBB;
            var pInMaxCombA = powerIOSoftLimit(pOutMaxCombCapA, minGain, "in");
            var pInMaxCombB = powerIOSoftLimit(pOutMaxCombCapB, minGain, "in");
            var pOutMaxCombEffA = defaultVds * (defaultPoutA / (nd * defaultVds));
            var pOutMaxCombEffB = defaultVds * (defaultPoutB / (nd * defaultVds));

            checkLimits($('#drain-volt-asym-a'), "Drain Voltage section A", "V", null, maxVds, (defaultVds / 2), null);
            checkLimits($('#drain-volt-asym-b'), "Drain Voltage section B", "V", null, maxVds, (defaultVds / 2), null);
            checkLimits($('#drain-curr-asym-a'), "Drain Current section A", "A", null, hardMaxIdsB, 0, null);
            checkLimits($('#drain-curr-asym-b'), "Drain Current section B", "A", null, hardMaxIdsB, 0, null);

            checkLimits(inputPowerAsymA, "Input Power section A", "W", null, pInMaxCombA, pInMinSoftA, Math.min(pInMaxSoftA, pInMaxCombA));
            checkLimits(inputPowerAsymB, "Input Power section B", "W", null, pInMaxCombB, pInMinSoftB, Math.min(pInMaxSoftB, pInMaxCombB));
            checkLimits(outputPowerAsymA, "Output Power section A", "W", null, Math.min(pOutMaxCombCapA,pOutMaxCombEffA), pOutMinSoftA, pOutMaxSoftA);
            checkLimits(outputPowerAsymB, "Output Power section B", "W", null, Math.min(pOutMaxCombCapB,pOutMaxCombEffB), pOutMinSoftB, pOutMaxSoftB);

        } else if (symmetry === "sym") { // check all symmetrical fields
            var inputPowerSym = $('#input-power-sym');
            var outputPowerSym = $('#output-power-sym');
            var pIn = defaultPin;
            var pOut = defaultPout;
            var pOutMaxCombEff = defaultVds * (defaultPout / (nd * defaultVds));

            //Input fields
            var pInMinSoft = powerIOSoftLimit(pOut, maxGain, "in");
            var pInMaxSoft = powerIOSoftLimit(pOut, minGain, "in");
            var pOutMinSoft = powerIOSoftLimit(pIn, minGain, "out");
            var pOutMaxSoft = powerIOSoftLimit(pIn, maxGain, "out");
            var pOutMaxCombCap = 1.5 * pOut1dBA;
            var pInMaxComb = powerIOSoftLimit(pOutMaxCombCap, minGain, "in");

            checkLimits($('#drain-volt-sym'), "Drain Voltage", "V", null, maxVds, (defaultVds / 2), null);
            checkLimits($('#drain-curr-sym'), "Drain Current", "A", null, hardMaxIds, 0, null);
            checkLimits(inputPowerSym, "Input Power", "W", null, pInMaxComb, pInMinSoft, Math.min(pInMaxSoft, pInMaxComb));
            checkLimits(outputPowerSym, "Output Power", "W", null, Math.min(pOutMaxCombCap,pOutMaxCombEff), pOutMinSoft, pOutMaxSoft);
        }

        if (!hardLimitReached) {
            getData();
        }
    };

    $('.unit-select').on('change', function () {
        var unit = $(this).val();
        var symIn = $('#case-temp');
        var symVal = symIn.val();

        var juncTempA = $('#junc-temp-a');
        var juncTempB = $('#junc-temp-b');

        if (unit === "celsius") {
            symIn.val(((symVal - 32) / 1.8).toFixed(1));
            $('#junc-unit').text('°C');

            $('#junc-unit-a').text('°C');
            $('#junc-unit-b').text('°C');
        } else if (unit === "fahrenheit") {
            symIn.val((symVal * 1.8 + 32).toFixed(1));
            $('#junc-unit').text('°F');

            $('#junc-unit-a').text('°F');
            $('#junc-unit-b').text('°F');
        } else {
            console.log("Temperature unit error");
        }
    });

    $('#input-holder input, #input-holder select').on('change', function () {
        $(this).removeClass('default');

        if (isGaN) {
        	updateRth();
        }

        checkInputLimits();
    });


    $('#symmetry-selector').on('change', function () {
        $('#sym').toggleClass('hidden');
        $('#asym').toggleClass('hidden');

        $('#rth-line-sym').toggleClass('hidden');
        $('#rth-line-asym').toggleClass('hidden');

        $('#output-sym').toggleClass('hidden');
        $('#output-asym').toggleClass('hidden');

        initData();
        checkInputLimits();
        getData();
    });

    $('#pulsed-cond-select').on('change', function () {
        var sym = $(this).closest('#calculator-section').find('#symmetry-selector').val();
        var rthContainerSym = $(this).closest('#transistor-properties-holder').find('#rth-line-sym');
        var rthContainerASym = $(this).closest('#transistor-properties-holder').find('#rth-line-asym');
        switch ($(this).val()) {
            case "0":
                rthContainerSym.find('input').val(rthA);
                rthContainerASym.find('input').val(rthB);
                break;
            case "1":
                rthContainerSym.find('input').val(zth1);
                rthContainerASym.find('input').val(zth1 * 2);
                break;
            case "2":
                rthContainerSym.find('input').val(zth2);
                rthContainerASym.find('input').val(zth2 * 2);
                break;
            case "3":
                rthContainerSym.find('input').val(zth3);
                rthContainerASym.find('input').val(zth3 * 2);
                break;
            case "4":
                rthContainerSym.find('input').val(zth4);
                rthContainerASym.find('input').val(zth4 * 2);
                break;
        }
        if (!hardLimitReached) {
        	getData();
        }
    });

    $(window).on('load', function () {
        initData();
        checkInputLimits();
        getData();
    });

    $('#product-select').on('change', function () {
        var dataPath;

        if (window.location.origin) {
            dataPath = window.location.origin + '' + ctxPath + '/design-tool/' + $(this).val() + ".html";
        } else {
            dataPath = window.location.protocol + '' + ctxPath + '/design-tool/' + $(this).val() + ".html";
        }

        window.location.assign(dataPath);
    });

    $('ul.graphs-tabs li').on('click', function () {
        var tab_id = $(this).attr('data-tab');

        $('ul.graphs-tabs li').removeClass('current');
        $('.graph-tab-content').removeClass('current');

        $(this).addClass('current');
        $('#' + tab_id).addClass('current');

        caseChart.reflow();
        junctionChart.reflow();
    });
}