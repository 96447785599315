$(function(){
	var body = $( 'body' );
	var header = $( 'header.header' ),
		navBar = header.find( 'nav.navbar' ),
		subHandlers = navBar.find( 'li.dropdown' ),
		topBar = header.find( '.top-bar' ),
		topBarContainer = topBar.find( '.container' ),
		breadcrumb = topBarContainer.find( '.row' ).first(),
        cookie = header.find( '.cookie-bar' ),
		dir = {
			up: "up",
			down: "down",
			left: "left",
			right: "right"
		},
		currentlyVisible = breadcrumb,
		globalTiming = 0.3,
		navSettings = {
			init: { opacity: 0, y: "20px", display: "none" },
			enter: { opacity: 1, y: "0", onComplete: function() {
				currentlyVisible = this.target;
			}},
			leave: { opacity: 0, onComplete: function() {
				TweenMax.set( this.target, { display: "none" });
			}}
		},
		otherSettings = {
			init:  { opacity: 0, scaleX: "1", display: "none" },
			enter: { opacity: 1, scaleX: "1", onComplete: function() {
				currentlyVisible = this.target;
			}},
			leave: { opacity: 0, scaleX: "1.02", onComplete: function() {
				TweenMax.set( this.target, { display: "none" });
			}}
		},
		tempHeight = 0,
		navAnim = new TimelineMax(),
		timer,
		hovering = false,
		down = false,
		responsiveBreakpoint = 768,
		focus = false;

    $( 'html' ).removeClass( 'no-js-nav' );
    navBar.find( '.dropdown-toggle' ).removeAttr( 'data-toggle' );
    TweenMax.to( cookie, globalTiming, { y: "60" } );

	var waypoint = new Waypoint( {
	  element: body,
	  handler: function(direction) {
		if( direction === dir.up ) {
			TweenMax.to( topBar, globalTiming, { y: "0" } );
            TweenMax.to( cookie, globalTiming, { y: "60" } );
			down = false;
		}
		if( direction === dir.down ) {
			if( $(window).width() < responsiveBreakpoint ) { return false; }
			down = true;
			if( !hovering ) {
				TweenMax.to( topBar, globalTiming, { y: "-50px" } );
                TweenMax.to( cookie, globalTiming, { y: "10" } );
			}
		}
	  },
	  offset: function() {
		return -1 * navBar.height();
	  }
	} );

	// Parse all li.dropdown
	$.each( subHandlers, function( index, subHandler ) {
		var $subHandler = $( subHandler );
		var subItem = $subHandler.find( ".row" ).clone();
		var isNav = subItem.hasClass( "sub" );

		// Populate the topBar
		topBarContainer.append( subItem );

		// Hide initally
		if( isNav ) {
			TweenMax.set( subItem, navSettings.init );
		} else {
			TweenMax.set( subItem, otherSettings.init );
		}

		function entering( callback ) {
			// Clear timer so that menu doesn't close
			clearTimeout( timer );

			if( $(window).width() < responsiveBreakpoint ) { return false; }


			// Set hovering to true
			hovering = true;
			// Animate the top bar to it's fully visible position (instead of sliden up)
			TweenMax.to( topBar, globalTiming, { y: "0" } );

			// If the this item is already visible; do nothing further
			if ( currentlyVisible === subItem ) { return false; }

			// Hide all arrows but show it for the current
			subHandlers.removeClass( 'show-arrow' );
			$subHandler.addClass( 'show-arrow' );

			// Stop any running navigation animations and start a fresh one
			navAnim.kill();
			navAnim = new TimelineMax();

			// Get the height to which we should animate
			tempHeight = subItem.outerHeight();

			// Get all items in the topbar except this one and hide them
			var others = topBarContainer.find( '.row' ).not( subItem );
			$.each( others, function( index, other ) {
				var $other = $( other );
				if( $other.hasClass( "sub" ) ) {
					TweenMax.to( $other, globalTiming, navSettings.leave );
				} else {
					TweenMax.to( $other, globalTiming, otherSettings.leave );
				}
			});

			if( isNav ) {
				navAnim.set( subItem, navSettings.init, "+="+globalTiming );
			} else {
				navAnim.set( subItem, otherSettings.init, "+="+globalTiming );
			}

			// Set current to visible
			navAnim.set( subItem, { display: "block" } );
			// Animate the topBar to the correct height
			navAnim.call( function() {
				currentlyVisible = subItem;
				tempHeight = subItem.outerHeight();
				TweenMax.to( topBar, globalTiming, { height: tempHeight } );
                TweenMax.to( cookie, globalTiming, { y: tempHeight } );
			});
			navAnim.to( subItem, globalTiming, navSettings.enter );
			navAnim.call( function() {
				if(typeof callback === "function") { callback( subItem ); }
			});

		}

		function leaving( callback ) {
			clearTimeout( timer );
			timer = setTimeout( function() {

				hovering = false;
				if( focus ) { return false; } //if the search has focussed do nothing

				currentlyVisible = breadcrumb;

				subHandlers.removeClass( 'show-arrow' );
				navAnim.kill();
				navAnim = new TimelineMax();

				var others = topBarContainer.find( '.row' ).not( breadcrumb );
				$.each( others, function( index, other ) {
					var $other = $( other );
					if( $other.hasClass( "sub" ) ) {
						TweenMax.to( $other, globalTiming, navSettings.leave );
					} else {
						TweenMax.to( $other, globalTiming, otherSettings.leave );
					}
				});
				tempHeight = breadcrumb.outerHeight();
				navAnim.to( topBar, globalTiming, { height: tempHeight } );
				navAnim.set( breadcrumb, { display: "block" } );
				navAnim.to( breadcrumb, globalTiming, otherSettings.enter );
				if( down ) {
					TweenMax.to( topBar, globalTiming, { y: "-50px" } );
                    TweenMax.to( cookie, globalTiming, { y: "10" } );
				} else if(tempHeight>0) {
                    TweenMax.to( cookie, globalTiming, { y: "60" } );
				}
				if(typeof callback === "function") { callback( subItem ); }
			}, 250 );

		}

		function mobileNav( target ) {
			var dT = target.find( '.dropdown-trigger' ),
				dTI = dT.find( '.lnr' ),
				dM = target.find( '.dropdown-menu' ),
				iconClosed = "lnr-plus",
				iconOpen = "lnr-minus",
				tempHeight = 0,
				open = false;


			$(window).on( "resizeend", function() {
				dTI.removeClass( iconOpen ).addClass( iconClosed );
				TweenMax.set(dM, {display: "none"} );
				open = false;
			});


			dT.on( "click", function( e ) {
				e.preventDefault();
				dM.css("display","block");
				tempHeight = dM.outerHeight( true );
				// dM.css("max-height", tempHeight + "xp");

				if( open ) {
					dTI.removeClass( iconOpen ).addClass( iconClosed );
					TweenMax.to(dM, 0.3, {height:0, clearProps:"height", onComplete: function() {
						dM.css("display","none");
					} });
					open = false;
				} else {
					dTI.removeClass( iconClosed ).addClass( iconOpen );
					TweenMax.from(dM, 0.3, {height:0});
					open = true;
				}
			} );


		}

		// Bind events
		if( $subHandler.hasClass( 'req-click' ) ) {
			$subHandler.on( "click", function(){
				entering( function( _subItem ) {
					_subItem.find('#nav-search').focus();
					focus = true;
					_subItem.find('#nav-search').one( 'blur', function(){
						focus = false;
						if(!hovering) { leaving(); }
					});

				});
			} );
		} else {
			$subHandler.on( "mouseover", entering );
			$subHandler.on( "mouseleave", leaving );
			mobileNav( $subHandler );
		}

		topBar.on( "mouseover", function() {
			clearTimeout( timer );
			hovering = true;
		});
		topBar.on( "mouseleave", leaving );
	});
		
	function checkFormSubmit(el){
		var nav = el.find('#nav-search');
		
		if(nav.hasClass('open')) {
			// check if input is available and minimal of 3 characters length
			if(nav.val() !== "" && nav.val().length > 2) {
				return true;
			}	
			else {
				return false;
			}
		}	
		else {
			return false;
		}
	}

	// Top navigation search expander

    $('.navbar .expand-search').on('click', function() {
        if ($('#nav-search').hasClass('open')) {
            $('.navbar .submit-btn').click();
        } else {
            $(this).parent().find('#nav-search').addClass('open');
        }
    });

	// Close expanded navigation search form on resize

    $(window).resize(function() {
        $('#nav-search').removeClass('open');
    });

	// Check if form is empty before initializing a regular search

    $('.expand-search').on('click',function(e){
        e.stopImmediatePropagation();

        if(checkFormSubmit($(this).parents('#tabPanelRegularSearch'))) {
            $('#tabPanelRegularSearch .submit-btn').click();
        }
    });

	$('#tabPanelRegularSearch').on('submit',function(e){
		if(checkFormSubmit($(this))) {
			return;
		}
		e.preventDefault();
	});

	// Mobile navigation search

    $('.navbar-toggle').on('click', function() {
        $('.search-form').toggle();
        $('#nav-main').toggleClass('mobile-main-nav');
    });

    // Headline link clickable area

    function headlineLinkClickableArea(headlineLinkElement) {
        var linkText;

        $(headlineLinkElement).each(function() {
            if ($(this).children().length === 0) {
                linkText = $(this).text();
                $(this).html('<span>'+ linkText +'</span>');
                $(this).css({'pointer-events':'none', 'cursor':'default'});
                $(this).find('span').css({'pointer-events': 'all', 'cursor':'pointer', 'display':'inline-block'});
            } else {
                linkText = $(this).find('span').html();
                $(this).css({'pointer-events':'none', 'cursor':'default'});
                $(this).find('span').css({'pointer-events': 'all', 'cursor':'pointer', 'display':'inline-block'});
            }
        });
	}

    if($('.video-header-headline a')) {
        headlineLinkClickableArea($('.video-header-headline a'));
    }

    if ($('.image-header-headline a')) {
        headlineLinkClickableArea($('.image-header-headline a'));
    }

	if ($('.image-header-wrapper')) {
		if (navigator.userAgent.includes('Firefox')) {
			$('.image-header-wrapper img').css({'top': '50%', 'transform': 'translate(0, -50%)'});
		}
	}

	/*
	$('#screensize .size').html(window.innerWidth+'x'+window.innerHeight);
	
	$(window).resize(function(){
		$('#screensize .size').html(window.innerWidth+'x'+window.innerHeight);
	});
	*/
});
